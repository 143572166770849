import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function LineTrimmers() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/line-trimmers" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Line Trimmer service and repair | Service Center</title>
        <meta
          name="description"
          content="Is your line trimmer hard to start, runs badly, or the cutting line won’t feed? Our expert mechanic has seen it all."
        />
      </Helmet>

      <Layout title="Line Trimmers">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p>
                    Is your line trimmer hard to start, runs badly, or the
                    cutting line won’t feed? Our expert mechanic has seen it
                    all. Bring your line trimmer into the team at Masterton
                    Mitre10 Service Centre and we will help you get your line
                    trimmer back to 100% in no time.
                  </p>
                  <p>
                    Do you need some help with regular maintenance? We offer
                    general servicing for all types of line trimmers. A general
                    service may include:
                  </p>
                  <ul>
                    <li>spark plug replacement</li>
                    <li>fluid and filter replacement</li>
                    <li>carburettor check and clean</li>
                    <li>line replacement</li>
                  </ul>
                  <p>
                    Call <a href="tel:063706926">06 370 6926</a> to arrange a
                    service.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
